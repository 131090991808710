//REGISTER
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

// LOGIN

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

//

export const ADDDOCTOR_SUCCESS = "ADDDOCTOR_SUCCESS";
export const ADDDOCTOR_FAILURE = "ADDDOCTOR_FAILURE";

export const GETDOCTOR_SUCCESS = "GETDOCTOR_SUCCESS";
export const GETDOCTOR_FAILURE = "GETDOCTOR_FAILURE";

export const GETCOUNTRY_SUCCESS = "GETCOUNTRY_SUCCESS";
export const GETCOUNTRY_FAILURE = "GETCOUNTRY_FAILURE";

export const FINDDOCTOR_SUCCESS = "FINDDOCTOR_SUCCESS";
export const FINDDOCTOR_FAILURE = "FINDDOCTOR_FAILURE";

export const UPLOAD_DOCTOR_SUCCESS = "UPLOAD_DOCTOR_SUCCESS";
export const UPLOAD_DOCTOR_FAILURE = "UPLOAD_DOCTOR_FAILURE";

export const EDITDOCTOR_SUCCESS = "EDITDOCTOR_SUCCESS";
export const EDITDOCTOR_FAILURE = "EDITDOCTOR_FAILURE";

export const GETDOCTORDETAIL_SUCCESS = "GETDOCTORDETAIL_SUCCESS";
export const GETDOCTORDETAIL_FAILURE = "GETDOCTORDETAIL_FAILURE";

export const DELETEDOCTOR_SUCCESS = "DELETEDOCTOR_SUCCESS";
export const DELETEDOCTOR_FAILURE = "DELETEDOCTOR_FAILURE";

//LOGOUT
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const REFRESH_FAILURE = "REFRESH_FAILURE";

//Otp
export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_FAILURE = "OTP_FAILURE";

//FORGOTPASSWORD
export const FORGOTPASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS";
export const FORGOTPASSWORD_FAILURE = "FORGOTPASSWORD_FAILURE";

//RESETPASSWORD
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAILURE = "RESETPASSWORD_FAILURE";

//Hospital
export const CREATE_HOSPITAL_SUCCESS = "CREATE_HOSPITAL_SUCCESS";
export const CREATE_HOSPITAL_FAILURE = "CREATE_HOSPITAL_FAILURE";

export const UPLOAD_HOSPITAL_SUCCESS = "UPLOAD_HOSPITAL_SUCCESS";
export const UPLOAD_HOSPITAL_FAILURE = "UPLOAD_HOSPITAL_FAILURE";

export const RETRIEVE_HOSPITALS_SUCCESS = "RETRIEVE_HOSPITALS_SUCCESS";
export const RETRIEVE_HOSPITALS_FAILURE = "RETRIEVE_HOSPITALS_FAILURE";

export const FIND_HOSPITALS_SUCCESS = "FIND_HOSPITALS_SUCCESS";
export const FIND_HOSPITALS_FAILURE = "FIND_HOSPITALS_FAILURE";

export const UPDATE_HOSPITAL_SUCCESS = "UPDATE_HOSPITAL_SUCCESS";
export const UPDATE_HOSPITAL_FAILURE = "UPDATE_HOSPITAL_FAILURE";

export const DELETE_HOSPITAL_SUCCESS = "DELETE_HOSPITAL_SUCCESS";
export const DELETE_HOSPITAL_FAILURE = "DELETE_HOSPITAL_FAILURE";

//Doctor SLOT-schedule
export const CREATE_DOCTOR_SLOT_SUCCESS = "CREATE_DOCTOR_SLOT_SUCCESS";
export const CREATE_DOCTOR_SLOT_FAILURE = "CREATE_DOCTOR_SLOT_FAILURE";

export const CREATE_DOCTOR_CONSULTATION_SUCCESS =
  "CREATE_DOCTOR_CONSULTATION_SUCCESS";
export const CREATE_DOCTOR_CONSULTATION_FAILURE =
  "CREATE_DOCTOR_CONSULTATION_FAILURE";

export const RETRIEVE_DOCTOR_SLOT_SUCCESS = "RETRIEVE_DOCTOR_SLOT_SUCCESS";
export const RETRIEVE_DOCTOR_SLOT_FAILURE = "RETRIEVE_DOCTOR_SLOT_FAILURE";

export const FIND_DOCTOR_FOR_CONSULTATION_SUCCESS =
  "FIND_DOCTOR_FOR_CONSULTATION_SUCCESS";
export const FIND_DOCTOR_FOR_CONSULTATION_FAILURE =
  "FIND_DOCTOR_FOR_CONSULTATION_FAILURE";

export const UPDATE_DOCTOR_SLOT_SUCCESS = "UPDATE_DOCTOR_SLOT_SUCCESS";
export const UPDATE_DOCTOR_SLOT_FAILURE = "UPDATE_DOCTOR_SLOT_FAILURE";

export const DELETE_DOCTOR_SLOT_SUCCESS = "DELETE_DOCTOR_SLOT_SUCCESS";
export const DELETE_DOCTOR_SLOT_FAILURE = "DELETE_DOCTOR_SLOT_FAILURE";

// Doctor Schedule
export const ADDSCHEDULE_SUCCESS = "ADDSCHEDULE_SUCCESS";
export const ADDSCHEDULE_FAILURE = "ADDSCHEDULE_FAILURE";

export const GETSCHEDULE_SUCCESS = "GETSCHEDULE_SUCCESS";
export const GETSCHEDULE_FAILURE = "GETSCHEDULE_FAILURE";

export const EDITSCHEDULE_SUCCESS = "EDITSCHEDULE_SUCCESS";
export const EDITSCHEDULE_FAILURE = "EDITSCHEDULE_FAILURE";

export const DELETESCHEDULE_SUCCESS = "DELETESCHEDULE_SUCCESS";
export const DELETESCHEDULE_FAILURE = "DELETESCHEDULE_FAILURE";

export const STATUSCHANGEDOCTOR_SUCCESS = "STATUSCHANGEDOCTOR_SUCCESS";
export const STATUSCHANGEDOCTOR_FAILURE = "STATUSCHANGEDOCTOR_FAILURE";

export const SCHEDULELEAVE_SUCCESS = "SCHEDULELEAVE_SUCCESS";
export const SCHEDULELEAVE_FAILURE = "SCHEDULELEAVE_FAILURE";

export const GETCONSULATIONSCHARGES_SUCCESS = "GETCONSULATIONSCHARGES_SUCCESS";
export const GETCONSULATIONSCHARGES_FAILURE = "GETCONSULATIONSCHARGES_FAILURE";

export const GET_SLOT_SUCCESS = "GET_SLOT_SUCCESS";
export const GET_SLOT_FAILURE = "GET_SLOT_FAILURE";

export const GETOVERVIEW_SUCCESS = "GETOVERVIEW_SUCCESS";
export const GETOVERVIEW_FAILURE = "GETOVERVIEW_FAILURE";

//doctor visiting hours
export const CREATE_DOCTOR_VISITING_SUCCESS = "CREATE_DOCTOR_VISITING_SUCCESS";
export const CREATE_DOCTOR_VISITING_FAILURE = "CREATE_DOCTOR_VISITING_FAILURE";

//Verification for Doctors by Hospital
export const CREATE_DOCTOR_VERIFICATION_SUCCESS =
  "CREATE_DOCTOR_VERIFICATION_SUCCESS";
export const CREATE_DOCTOR_VERIFICATION_FAILURE =
  "CREATE_DOCTOR_VERIFICATION_FAILURE";
export const GET_DOCTOR_FOR_VERIFICATION_SUCCESS =
  "GET_DOCTOR_FOR_VERIFICATION_SUCCESS";
export const GET_DOCTOR_FOR_VERIFICATION_FAILURE =
  "GET_DOCTOR_FOR_VERIFICATION_FAILURE";
export const GET_DOCTOR_FOR_VERIFICATION_DOCUMENTS_SUCCESS =
  "GET_DOCTOR_FOR_VERIFICATION_DOCUMENTS_SUCCESS";
export const GET_DOCTOR_FOR_VERIFICATION_DOCUMENTS_FAILURE =
  "GET_DOCTOR_FOR_VERIFICATION_DOCUMENTS_FAILURE";

//Verification for Hospital by Super Admin
export const CREATE_HOSPITAL_VERIFICATION_SUCCESS =
  "CREATE_HOSPITAL_VERIFICATION_SUCCESS";
export const CREATE_HOSPITAL_VERIFICATION_FAILURE =
  "CREATE_HOSPITAL_VERIFICATION_FAILURE";
export const GET_HOSPITAL_FOR_VERIFICATION_SUCCESS =
  "GET_HOSPITAL_FOR_VERIFICATION_SUCCESS";
export const GET_HOSPITAL_FOR_VERIFICATION_FAILURE =
  "GET_HOSPITAL_FOR_VERIFICATION_FAILURE";
export const GET_HOSPITAL_FOR_VERIFICATION_DOCUMENTS_SUCCESS =
  "GET_HOSPITAL_FOR_VERIFICATION_DOCUMENTS_SUCCESS";
export const GET_HOSPITAL_FOR_VERIFICATION_DOCUMENTS_FAILURE =
  "GET_HOSPITAL_FOR_VERIFICATION_DOCUMENTS_FAILURE";

//Verification for Doctoe by Super Admin

export const CREATE_INDIVIDUAL_DOCTOR_VERIFICATION_SUCCESS =
  "CREATE_INDIVIDUAL_DOCTOR_VERIFICATION_SUCCESS";
export const CREATE_INDIVIDUAL_DOCTOR_VERIFICATION_FAILURE =
  "CREATE_INDIVIDUAL_DOCTOR_VERIFICATION_FAILURE";
export const GET_INDIVIDUAL_DOCTOR_FOR_VERIFICATION_SUCCESS =
  "GET_INDIVIDUAL_DOCTOR_FOR_VERIFICATION_SUCCESS";
export const GET_INDIVIDUAL_DOCTOR_FOR_VERIFICATION_FAILURE =
  "GET_INDIVIDUAL_DOCTOR_FOR_VERIFICATION_FAILURE";
export const GET_INDIVIDUAL_DOCTOR_FOR_VERIFICATION_DOCUMENTS_SUCCESS =
  "GET_INDIVIDUAL_DOCTOR_FOR_VERIFICATION_DOCUMENTS_SUCCESS";
export const GET_INDIVIDUAL_DOCTOR_FOR_VERIFICATION_DOCUMENTS_FAILURE =
  "GET_INDIVIDUAL_DOCTOR_FOR_VERIFICATION_DOCUMENTS_FAILURE";

export const GETPRODUCTADS_SUCCESS = "GETPRODUCTADS_SUCCESS";
export const GETPRODUCTADS_FAILURE = "GETPRODUCTADS_FAILURE";
export const ADDPRODUCTADS_SUCCESS = "ADDPRODUCTADS_SUCCESS";
export const ADDPRODUCTADS_FAILURE = "ADDPRODUCTADS_FAILURE";
export const VERIFYADVERTISEMENT_SUCCESS = "VERIFYADVERTISEMENT_SUCCESS";
export const VERIFYADVERTISEMENT_FAILURE = "VERIFYADVERTISEMENT_FAILURE";

// AddEvent
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILURE = "CREATE_EVENT_FAILURE";
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";
export const GET_DEPARTMENT_FAILURE = "GET_DEPARTMENT_FAILURE";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";
export const INTERESTED_EVENT_SUCCESS = "INTERESTED_EVENT_SUCCESS";
export const INTERESTED_EVENT_FAILURE = "INTERESTED_EVENT_FAILURE";
export const NOTINTERESTED_EVENT_SUCCESS = "NOTINTERESTED_EVENT_SUCCESS";
export const NOTINTERESTED_EVENT_FAILURE = "NOTINTERESTED_EVENT_FAILURE";
export const GETEVENTDETAIL_SUCCESS = "GETEVENTDETAIL_SUCCESS";
export const GETEVENTDETAIL_FAILURE = "GETEVENTDETAIL_FAILURE";

export const RETRIEVE_PROFILE_SUCCESS = "RETRIEVE_PROFILE_SUCCESS";
export const RETRIEVE_PROFILE_FAILURE = "RETRIEVE_PROFILE_FAILURE";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

//HR Constants

export const GETHR_SUCCESS = "GETHR_SUCCESS";
export const GETHR_FAILURE = "GETHR_FAILURE";

export const GETHRLIST_SUCCESS = "GETHRLIST_SUCCESS";
export const GETHRLIST_FAILURE = "GETHRLIST_FAILURE";

export const GETCANDIDATELIST_SUCCESS = "GETCANDIDATELIST_SUCCESS";
export const GETCANDIDATELIST_FAILURE = "GETCANDIDATELIST_FAILURE";

export const ADDHR_SUCCESS = "ADDHR_SUCCESS";
export const ADDHR_FAILURE = "ADDHR_FAILURE";

export const GETDROPDOWNLIST_SUCCESS = "GETDROPDOWNLIST_SUCCESS";
export const GETDROPDOWNLIST_FAILURE = "GETDROPDOWNLIST_FAILURE";

export const FINDHR_SUCCESS = "FINDHR_SUCCESS";
export const FINDHR_FAILURE = "FINDHR_FAILURE";

export const GETHRVACANCYDETAIL_SUCCESS = "GETHRVACANCYDETAIL_SUCCESS";
export const GETHRVACANCYDETAIL_FAILURE = "GETHRVACANCYDETAIL_FAILURE";

export const UPLOAD_HR_SUCCESS = "UPLOAD_HR_SUCCESS";
export const UPLOAD_HR_FAILURE = "UPLOAD_HR_FAILURE";

export const EDITHR_SUCCESS = "EDITHR_SUCCESS";
export const EDITHR_FAILURE = "EDITHR_FAILURE";

export const SENDOTP_SUCCESS = "SENDOTP_SUCCESS";
export const SENDOTP_FAILURE = "SENDOTP_FAILURE";

export const SAVEOTP_SUCCESS = "SAVEOTP_SUCCESS";
export const SAVEOTP_FAILURE = "SAVEOTP_FAILURE";

export const GETHRDETAIL_SUCCESS = "GETHRDETAIL_SUCCESS";
export const GETHRDETAIL_FAILURE = "GETHRDETAIL_FAILURE";

export const GETHRVACANCY_SUCCESS = "GETHRVACANCY_SUCCESS";
export const GETHRVACANCY_FAILURE = "GETHRVACANCY_FAILURE";

export const GETAPPLIEDCANDIDATE_SUCCESS = "GETAPPLIEDCANDIDATE_SUCCESS";
export const GETAPPLIEDCANDIDATE_FAILURE = "GETAPPLIEDCANDIDATE_FAILURE";

export const GETAPPLIEDCANDIDATEDETAIL_SUCCESS =
  "GETAPPLIEDCANDIDATEDETAIL_SUCCESS";
export const GETAPPLIEDCANDIDATEDETAIL_FAILURE =
  "GETAPPLIEDCANDIDATEDETAIL_FAILURE";

export const ADDFILTERCANDIDATE_SUCCESS = "ADDFILTERCANDIDATE_SUCCESS";
export const ADDFILTERCANDIDATE_FAILURE = "ADDFILTERCANDIDATE_FAILURE";

export const GETFILTEREDCANDIDATE_SUCCESS = "GETFILTEREDCANDIDATE_SUCCESS";
export const GETFILTEREDCANDIDATE_FAILURE = "GETFILTEREDCANDIDATE_FAILURE";

export const GETFILTEREDCANDIDATEDETAIL_SUCCESS =
  "GETFILTEREDCANDIDATEDETAIL_SUCCESS";
export const GETFILTEREDCANDIDATEDETAIL_FAILURE =
  "GETFILTEREDCANDIDATEDETAIL_FAILURE";

export const ADDSELECTCANDIDATE_SUCCESS = "ADDSELECTCANDIDATE_SUCCESS,";
export const ADDSELECTCANDIDATE_FAILURE = "ADDSELECTCANDIDATE_FAILURE";

export const GETSHORTLISTCANDIDATE_SUCCESS = "GETSHORTLISTCANDIDATE_SUCCESS";
export const GETSHORTLISTCANDIDATE_FAILURE = "GETSHORTLISTCANDIDATE_FAILURE";

export const GETSHORTLISTEDCANDIDATEDETAIL_SUCCESS =
  "GETSHORTLISTEDCANDIDATEDETAIL_SUCCESS";
export const GETSHORTLISTEDCANDIDATEDETAIL_FAILURE =
  "GETSHORTLISTEDCANDIDATEDETAIL_FAILURE";

export const GETADVERTISEMENT_SUCCESS = "GETADVERTISEMENT_SUCCESS";
export const GETADVERTISEMENT_FAILURE = "GETADVERTISEMENT_FAILURE";

export const GETADVERTISEMENTDETAIL_SUCCESS = "GETADVERTISEMENTDETAIL_SUCCESS";
export const GETADVERTISEMENTDETAIL_FAILURE = "GETADVERTISEMENTDETAIL_FAILURE";

export const DELETEVACANCY_SUCCESS = "DELETEVACANCY_SUCCESS";
export const DELETEVACANCY_FAILURE = "DELETEVACANCY_FAILURE";

export const SUBMITACCEPTANCE_HR_SUCCESS = "SUBMITACCEPTANCE_HR_SUCCESS";
export const SUBMITACCEPTANCE_HR_FAILURE = "SUBMITACCEPTANCE_HR_FAILURE";

export const DELETEHR_SUCCESS = "DELETEHR_SUCCESS";
export const DELETEHR_FAILURE = "DELETEHR_FAILURE";
